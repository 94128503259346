import React from "react"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Helmet from "react-helmet";
import { Button } from 'antd';

const TwbaAPPDownload = () => {
    const og = {
        title: '全國律師聯合會 APP',
        description: '全國律師聯合會 APP 下載頁 : Android / iOS',
        url: BASE_URL + '/twba-app-download/',
        image: '/images/twba-app-download.png',
    }

    return (
        <FullScreenContainer extraStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: '#EEEEEE', }}>

            <SEO og={og} />

            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#dddddd', maxWidth: 600, minWidth: 250 }}>
                <img src="/images/twba-app-download-2.png" style={{ width: '100%' }} />

                <div style={{ flex: 1 }} />

                {

                    [
                        ['/images/apple.png', '/images/twba-apple.png', 'iOS 下載', 'https://l.revtel.app/j/twba-ios-app', '/images/ios-go.png'],
                        ['/images/google-play.png', '/images/twba-google.png', 'Android 下載', 'https://l.revtel.app/j/twba-android-app', '/images/android-go.png'],
                    ].map(([logo, qrcode, text, url, goimage], idx) => {

                        return (
                            <a href={url} target="_blank" style={{ marginBottom: 10, width: '90%', maxWidth: 450, borderRadius: 5, textDecoration: 'none', display: 'flex', flexDirection: 'row', backgroundColor: '#FEF9F2', paddingTop: 5, paddingBottom: 5 }} key={idx} rel="noreferrer">
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={qrcode} style={{ width: '50%', minWidth: 100, maxWidth: 200 }} />
                                </div>
                                <div style={{ flex: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 5 }}>
                                    <p style={{ color: 'black' }}>{text}</p>
                                    <img src={goimage} style={{ width: '80%', maxWidth: 120 }} />
                                </div>
                            </a>
                        )
                    })

                }
                <div style={{ flex: 0.5 }} />
                <div style={{ width: '80%', fontSize: 16, textAlign: 'center' }}>©2024 TAIWAN BAR ASSOCIATION All Rights Reserved</div>
                <p style={{ fontSize: 12, marginTop: 5, marginBottom: 5 }}>
                    Designed by &nbsp;
                    <a rel="noreferrer" style={{ width: '80%', textAlign: 'center', color: 'grey' }} href='https://www.revtel.tech/' target='_blank'>RevtelTech 忻旅科技</a>
                </p>
            </div>

        </FullScreenContainer>
    )
}

const FullScreenContainer = ({ children, extraStyle = {} }) => {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            overflow: 'hidden',
            ...extraStyle
        }}>
            {children}
        </div>
    );
};

const BASE_URL = 'https://www.sam-huang.info'
const SEO = ({ og }) => {
    const seo = {
        title: og.title,
        image: BASE_URL + (og.image || '/images/sky.png'),
        description: og.description || ' ',
        url: og.url || BASE_URL
    }
    return (
        <Helmet

            title={seo.title}
            meta={[
                {
                    name: `description`,
                    content: seo.description
                },
                {
                    property: `image`,
                    content: seo.image
                },
                {
                    property: `name`,
                    content: seo.title
                },
                {
                    property: `og:title`,
                    content: seo.title
                },
                {
                    property: `og:url`,
                    content: seo.url
                },
                {
                    property: `og:site_name`,
                    content: '全國律師聯合會 APP 下載',
                },
                {
                    property: `og:image`,
                    content: seo.image
                },
                {
                    property: `og:description`,
                    content: seo.description
                },
                {
                    property: 'og:url',
                    content: seo.url,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:image`,
                    content: seo.image
                },
                {
                    name: `twitter:creator`,
                    content: 'Sam Huang,黃奕翔'
                },
                {
                    name: `twitter:title`,
                    content: seo.title
                },
                {
                    name: `twitter:description`,
                    content: seo.description
                },
                {
                    property: 'keywords',
                    content: 'Sam Huang, Sam, sailplaneTW, 黃奕翔, 賣飛機的, 忻旅科技, Revtel, RevtelTech, 奕果雲端, Eculture, EcultureTech, React, React Native, 加密鏈, 軟體開發, 軟體顧問, 新創營運, web3, 區塊鏈,全國律師聯合會',
                },
            ]}
        />
    )
}



export default TwbaAPPDownload
